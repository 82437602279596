import React from "react"
import SEO from "../components/SEO"
import Footer from "../components/footer"
import Header from "../components/header"
const loadText = require("src/utils").loadText
const static_data = loadText("dc")
import "../styles/dc.less"

const Blog = ({ pageContext }) => {
  //   const { event, pageNum, } = pageContext
  // console.log(pageContext)
  //   console.log(pageContext)

  const Item = ({ field }) => (
    <div className="dc-content-item">
      <div className="font-28 title">
        <img src={`/dc/${field}.svg`} alt={field} />
        {static_data.subject[field]}
      </div>
      <div
        className="font-20 mt-16"
        dangerouslySetInnerHTML={{ __html: pageContext[field] }}
      />
    </div>
  )
  return (
    <div className="dc">
      <SEO title={`${pageContext.name} | 上海层峰`} />
      <Header logo="/logo-blue.png" />
      <div className="dc-topBanner-wrap">
        <div
          className="dc-topBanner"
          style={{
            backgroundImage: `url("${pageContext.background.localFile.publicURL}")`,
          }}
        >
          <div className="dc-topBanner-mask"></div>
          <div className="page-middle dc-topBanner-content">
            <div className="font-28">
              {static_data.subject.ts.toUpperCase()}
            </div>
            <div className="font-56 semi-bold mt-24">{pageContext.name}</div>
          </div>
        </div>
        <div className="dc-topBanner-intro">
          <div className="dc-topBanner-intro-content page-middle">
            <div className="font-28 semi-bold">
              {static_data.topIntro.title}
            </div>
            <div className="font-16 mt-24" style={{ maxWidth: "1036px" }}>
              {static_data.topIntro.description}
            </div>
          </div>
        </div>
      </div>

      <div className="dc-content-wrap full-bg">
        <div className="page-middle dc-content">
          {[
            ["contact", "location", "preview"],
            ["airport", "building", "fireProtection", "certification"],
            ["sla", "power", "cooling", "security"],
          ].map((row, idx) => (
            <div className="dc-content-downrow" key={idx}>
              {row.map(field =>
                field === "preview" ? (
                  <div className="dc-content-item">
                    <img
                      src={pageContext.preview.localFile.publicURL}
                      className="preview"
                    />
                  </div>
                ) : (
                  <Item field={field} key={field} />
                )
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Blog
